@charset "UTF-8";
body,
html {
  font-family: 'Roboto', sans-serif;
  margin: 0 auto !important;
  padding: 0 !important;
  font-size: 100%;
  font-weight: normal;
  color: #454544;
  background: #fbfbfb;
  overflow-x: hidden !important;
}
p {
  word-wrap: break-word !important;
}
*:focus,
*:hover {
  outline-color: transparent !important;
}
* {
  box-sizing: border-box;
}
img {
  max-width: 100%;
}
a:hover {
  text-decoration: none;
}
a:focus {
  text-decoration: none;
}
.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
h1 {
  font-size: 2rem;
  font-weight: 700;
}
h2 {
  font-size: 1.5rem;
  font-weight: 700;
}
h3 {
  font-size: 1.4rem;
  font-weight: 700;
}
h4 {
  font-size: 1.3rem;
  font-weight: 700;
}
h5 {
  font-size: 1.2rem;
  font-weight: 700;
}
h6 {
  font-size: 1.1rem;
  font-weight: 700;
}
blockquote {
  background: #fbfbfb;
  padding: 15px;
  border-left: 3px solid #4bbe50;
  font-style: italic;
  font-size: .95rem;
}
.shadow-0 {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
}
.shadow-1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.12);
}
.shadow-2 {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.shadow-3 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.shadow-4 {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
a {
  color: #4bbe50;
}
a:hover {
  color: #299c2e;
}
ol,
ul {
  padding-left: 25px;
}
ol li,
ul li {
  margin-bottom: 10px;
}
.main {
  position: relative;
  width: 100%;
  height: auto;
  padding: 30px 0;
  min-height: calc(100vh - 50px);
}
.cart {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.12);
  transition: .3s;
  position: relative;
  display: block;
  margin: 0 auto 30px auto;
  transform: translateY(0px);
}
.cart:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  transform: translateY(-2px);
}
.cart:hover .box span {
  color: #188b1d;
}
.cart .img {
  display: inline-block;
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.cart .box {
  padding: 10px 15px 15px 15px;
  color: #454544;
  min-height: 163px;
}
.cart .box h3 {
  font-size: 1.1rem;
  font-weight: 700;
}
.cart .box p {
  min-height: 65px;
  font-size: .85rem;
}
.cart .box span {
  color: #4bbe50;
}
/* Post */
.section_main {
  position: relative;
  width: 100%;
  height: auto;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.12);
  margin-bottom: 30px;
}
.section_main .img_post {
  width: 100%;
  height: 350px;
  object-fit: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.section_main .content {
  padding: 15px;
}
.section_main .content .title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 30px;
  line-height: 1.1;
}
.section_main .content h2,
.section_main .content h3,
.section_main .content h4,
.section_main .content h5,
.section_main .content h6 {
  margin: 25px 0 10px 0;
}
.section_right {
  position: relative;
  width: 100%;
  height: auto;
}
.navs {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.12);
  padding: 10px 15px;
  margin-bottom: 30px;
}
.breadcrumbs {
  font-size: .85rem;
}
.breadcrumbs a,
.breadcrumbs span {
  display: inline-block;
  vertical-align: top;
}
.social a {
  display: inline-block;
  vertical-align: middle;
  height: 25px;
  margin: 0 2px;
}
.social a:hover svg {
  transition: .3s;
  fill: #4bbe50;
}
.social a svg {
  transition: .3s;
  width: 25px;
  height: 25px;
  fill: #454544;
}
.bottom_block {
  padding: 20px 15px 10px 15px;
  background: #f8f9fa;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.bottom_block p {
  margin-bottom: 15px;
}
.comments_block {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.12);
  padding: 10px;
  margin-bottom: 30px;
}
.top_block {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.12);
  background: transparent;
  margin-bottom: 30px;
  padding: 11px 15px;
  padding: 12px 15px;
  font-size: .9rem;
  color: #888;
}
.post_block {
  position: relative;
  margin-bottom: 30px;
}
.post_block .title {
  margin-bottom: 15px;
  text-transform: uppercase;
  font-size: .9rem;
  font-weight: 700;
}
.link_block {
  display: block;
  margin-bottom: 10px;
  padding: 12px 15px;
  transform: translateY(0px);
  cursor: pointer;
  font-size: .85rem;
  transition: .3s;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.12);
  background: #fbfbfb;
}
.link_block:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background: #fff;
  transform: translateY(-2px);
}
/* Post END */
/* 404 */
.error {
  position: relative;
  width: 100%;
  height: auto;
  padding: 30px 0;
}
.error .row {
  min-height: calc(100vh - 109px);
}
.error h1 {
  font-size: 10rem;
}
.error small {
  display: block;
  margin-bottom: 15px;
}
/* 404 END */
footer {
  padding: 15px 0;
  color: #fff;
  background: #454544;
  text-align: center;
  font-size: .8rem;
}
@media all and (max-width: 1199px) {
  .section_main .img_post {
    height: auto;
  }
}
@media all and (max-width: 767px) {
  body,
  html {
    font-size: 95%;
  }
  .breadcrumbs {
    margin: 10px 0;
  }
  .section_main .content {
    padding: 25px 15px;
  }
  .cart .img {
    height: auto;
    object-fit: contain;
  }
}
