@charset "UTF-8";
body, html {
    font-family: 'Roboto', sans-serif;
    margin: 0 auto !important;
    padding: 0 !important;
    font-size: 100%;
    font-weight: normal;
    color: @default;
    background: @secondary;
    overflow-x: hidden !important;
}

p {word-wrap: break-word !important;}
* {&:focus,&:hover {outline-color: transparent !important;}}
* {box-sizing: border-box;}
img {max-width: 100%;}
a {&:hover {text-decoration: none;}&:focus {text-decoration: none;}}
.text-overflow { overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
h1 {font-size: 2rem; font-weight: 700;}
h2 {font-size: 1.5rem; font-weight: 700;}
h3 {font-size: 1.4rem; font-weight: 700;}
h4 {font-size: 1.3rem; font-weight: 700;}
h5 {font-size: 1.2rem; font-weight: 700;}
h6 {font-size: 1.1rem; font-weight: 700;}
blockquote {
    background: @secondary;
    padding: 15px;
    border-left: 3px solid @primary;
    font-style: italic;
    font-size: .95rem;
}
.shadow-0 {box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);}
.shadow-1 {box-shadow: 0 1px 3px rgba(0,0,0,.16),0 1px 3px rgba(0,0,0,.12);}
.shadow-2 {box-shadow: 0 3px 6px rgba(0,0,0,.16) , 0 3px 6px rgba(0,0,0,.23);}
.shadow-3 {box-shadow: 0 10px 20px rgba(0,0,0,.19) , 0 6px 6px rgba(0,0,0,.23);}
.shadow-4 {box-shadow: 0 14px 28px rgba(0,0,0,.25) , 0 10px 10px rgba(0,0,0,.22);}

@default: #454544;
@primary: #4bbe50;
@secondary: #fbfbfb;
@tertiary: #f8f9fa;

.def() {position: relative;width: 100%;height: auto;}
.anim-03() {transition: .3s;}
.row-custom() {
    .row {
        margin: 0 -5px;

        *[class*='col-'] {
            padding: 0 5px;
        }
    }
}
.default-el(){
    border-radius: 5px;
    background: #fff;
    .shadow-1;
}
a {
    color: @primary;
    &:hover {
        color: @primary - #222;
    }
}
ol,
ul {
    padding-left: 25px;
    li {
        margin-bottom: 10px;
    }
}