@import 'components.less';
@charset "UTF-8";

.main {
	.def;
	padding: 30px 0;
	min-height: ~"calc(100vh - 50px)";
}

.cart {
	.default-el;
	.anim-03;
	position: relative;
	display: block;
	margin: 0 auto 30px auto;
	transform: translateY(0px);
	&:hover {
		.shadow-3;
		transform: translateY(-2px);
		.box {
			span {
				color: @primary - #333;
			}
		}
	}
	.img {
		display: inline-block;
		width: 100%;
		height: 200px;
		object-fit: cover;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}
	.box {
		padding: 10px 15px 15px 15px;
		color: @default;
		min-height: 163px;
		h3 {
			font-size: 1.1rem;
			font-weight: 700;
		}
		p {
			min-height: 65px;
			font-size: .85rem;
		}
		span {
			color: @primary;
		}
	}
}

/* Post */

.section_main {
	.def;
	.default-el;
	margin-bottom: 30px;
	.img_post {
		width: 100%;
		height: 350px;
		object-fit: cover;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}
	.content {
		padding: 15px;
		.title {
			font-size: 2rem;
			font-weight: 700;
			margin-bottom: 30px;
			line-height: 1.1;
		}
		h2,h3,h4,h5,h6 {
			margin: 25px 0 10px 0;
		}
	}
}
.section_right {
	.def;
}
.navs {
	.default-el;
	padding: 10px 15px;
	margin-bottom: 30px;
}
.breadcrumbs {
	font-size: .85rem;
	a,
	span {
		display: inline-block;
		vertical-align: top;
	}
}
.social {
	a {
		display: inline-block;
		vertical-align: middle;
		height: 25px;
		margin: 0 2px;
		&:hover {
			svg {
				.anim-03;
				fill: @primary;
			}
		}
		svg {
			.anim-03;
			width: 25px;
			height: 25px;
			fill: @default;
		}
	}
}
.bottom_block {
	padding: 20px 15px 10px 15px;
	background: @tertiary;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	p {
		margin-bottom: 15px;
	}
}

.comments_block {
	.default-el;
	padding: 10px;
	margin-bottom: 30px;
}
.top_block {
	.default-el;
	background: transparent;
	margin-bottom: 30px;
	padding: 11px 15px;
	padding: 12px 15px;
	font-size: .9rem;
	color: #888;
}
.post_block {
	position: relative;
	margin-bottom: 30px;
	.title {
		margin-bottom: 15px;
		text-transform: uppercase;
		font-size: .9rem;
		font-weight: 700;
	}
}
.link_block {
	display: block;
	margin-bottom: 10px;
	padding: 12px 15px;
	transform: translateY(0px);
	cursor: pointer;
	font-size: .85rem;
	.anim-03;
	.default-el;
	background: @secondary;
	&:hover {
		.shadow-2;
		background: #fff;
		transform: translateY(-2px);
	}
}

/* Post END */

/* 404 */
.error {
	.def;
	padding: 30px 0;
	.row {
		min-height: ~"calc(100vh - 109px)";
	}
	h1 {
		font-size: 10rem;
	}
	small {
		display: block;
		margin-bottom: 15px;
	}
}
/* 404 END */

footer {
	padding: 15px 0;
	color: #fff;
	background: @default;
	text-align: center;
	font-size: .8rem;
}

@media all and (max-width: 1199px) {
	.section_main .img_post {
		height: auto;
	}
}

@media all and (max-width: 767px) {
	body, html {
		font-size: 95%;
	}
	.breadcrumbs {
		margin: 10px 0;
	}
    .section_main .content {
	    padding: 25px 15px;
	}
	.cart .img {
		height: auto;
		object-fit: contain;
	}
}